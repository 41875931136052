const colors = ['indigo', 'success', 'warning', 'error', 'info', 'teal', 'primary', 'green', 'orange', 'blue'];
export const generateChips = (item) => {

	const { listing_type, title } = item;
	const words = title.toLowerCase().split(' ');
	const chips = [];
	if (words.includes('private') && listing_type === 'PRIVATE_TRANSFER') {
		chips.push({ text: 'Private Transfer', color: getColor() });
		chips.push({ text: 'Minivan', color: getColor() });
	}
	if (words.includes('shared') && listing_type === 'SHARED_TRANSFER') {
		chips.push({ text: 'Shared Transfer', color: getColor() });
		chips.push({ text: 'Minivan', color: getColor() });
		chips.push({ text: 'Budget-friendly', color: getColor() });
		chips.push({ text: "Backpacker's Choice", color: getColor() });
	}
	if (words.includes('underground')) {
		chips.push({ text: 'Private Transfer', color: getColor() });
		chips.push({ text: 'Guided Tour', color: getColor() });
		chips.push({ text: 'Underground River Tour', color: getColor() });
	}
	if (words.includes('island') && words.includes('hopping')) {
		chips.push({ text: 'Island Hopping', color: getColor() });
		if (words.includes('private')) {
			chips.push({ text: 'Private Tour', color: getColor() });
		}
	}
	return getRandomSubset(chips.length, chips);
};

const getColor = () => colors[Math.floor(Math.random() * colors.length)];