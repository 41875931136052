import { defineStore } from 'pinia';
export const useCurrencyStore = defineStore('currency', {
    state: () => ({
        currency: null,
        locale: null,
        currencyData: null,
    }),
    actions: {
        setInitialData(locale, currencyData) {
            this.currency = currencyData.currencyCode,
                this.locale = locale
            this.currencyData = currencyData;
        },

    },
    getters: {
        getUserCurrency: (state) => state.currency,
        getUserLocale: (state) => state.locale,
        getCurrencyData: (state) => state.currencyData
    },
    persist: {
        storage: persistedState.cookiesWithOptions(
            {
                maxAge: 60 * 60 * 48,
            }
        )
    }
});