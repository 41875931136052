import { defineStore } from 'pinia';
export const useConversionStore = defineStore('conversion', {
    state: () => ({
        queries: {},
    }),
    actions: {
        setQueries(data) {
            const bookingStore = useBookingStore();
            const { bookingRef } = storeToRefs(bookingStore);
            this.queries = { ...this.queries, ...data, ref: bookingRef.value };
            apiPost('/api/tracking', this.queries);

        },
        resetState() {
            this.queries = {};
        }
    },
    getters: {
        getQueries(state) {
            return state.queries;
        }
    },
    persist: {
        storage: persistedState.localStorage,
    }
});