const baseURI = process.env.VERCEL_URL ? process.env.VERCEL_URL : process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'www.diskubrepalawan.com';
const endpoint = (url: string) => {
    if (url.startsWith('http')) return url;
    const proto = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    return url.startsWith('/') ? `${proto}://${baseURI}${url}` : `${proto}://${baseURI}/${url}`;
}

export const apiPost = async (url: string, data: any) => {
    const apiEndpoint = endpoint(url);
    const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    return response.json();
}

export const apiGet = async (url: string, headers: any = {}) => {
    const apiEndpoint = endpoint(url);
    const res = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        }
    });

    return await res.json();
}