import { allImages } from './objectHelpers';
const images = Object.values(allImages).reduce((acc, item) => {
	const imgData = item.map((i) => `https://www.diskubrepalawan.com${i}`);
	return acc.concat(...imgData);
}, []);
const newDay = (days: number): string => {
	const currentDate = new Date();
	currentDate.setDate(currentDate.getDate() + days);

	const year = currentDate.getFullYear();
	const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
	const day = currentDate.getDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
};
const business = {
	'@context': 'http://schema.org/',
	'@type': 'TravelAgency',
	name: 'Diskubre Travel Palawan',
	url: 'https://www.diskubrepalawan.com',
	logo: 'https://www.diskubrepalawan.com/images/logos/logo.png',
	telephone: '+63 963 216 0905',
	priceRange: '$10 - $270',
	review: [],
	contactPoint: {
		'@type': 'ContactPoint',
		telephone: '+63 963 216 0905',
		contactType: 'customer service',
	},
	image: [
		...images,
		'https://www.diskubrepalawan.com/images/destinations/svc.jpg',
		'https://www.diskubrepalawan.com/images/destinations/blb.jpg',
		'https://www.diskubrepalawan.com/images/destinations/sab.jpg',
		'https://www.diskubrepalawan.com/images/destinations/pps.jpg',
		'https://www.diskubrepalawan.com/images/destinations/pbt.jpg',
		'https://www.diskubrepalawan.com/images/destinations/eln.jpg',
		'https://www.diskubrepalawan.com/images/logos/logo.png',
	],
	geo: {
		'@type': 'GeoCoordinates',
		latitude: 9.790365,
		longitude: 18.729792,
	},
	sameAs: ['https://www.facebook.com/diskubrepalawan', 'https://www.instagram.com/diskubrepalawan'],
	paymentAccepted: 'Cash, Credit Card',
	currenciesAccepted: 'AED, ARS, AUD, BRL, CAD, CHF, CLP, CNY, CZK, DKK, EUR, GBP, HKD, HUF, IDR, ILS, INR, JPY, KRW, MXN, MYR, NOK, NZD, PHP, PLN, RUB, SAR, SEK, SGD, THB, TRY, TWD, USD, ZAR',
	openingHoursSpecification: [
		{
			'@type': 'OpeningHoursSpecification',
			dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
			opens: '00:00',
			closes: '00:00',
		},
	],
	address: {
		'@type': 'PostalAddress',
		streetAddress: 'Mt. View, Sta. Monica',
		addressLocality: 'Puerto Princesa City',
		addressRegion: 'Palawan, MIMAROPA',
		postalCode: '5300',
		addressCountry: 'PH',
	},
	aggregateRating: {
		'@type': 'AggregateRating',
		ratingValue: 5,
		reviewCount: 1165,
		bestRating: 5,
		worstRating: 3,
	},
};

const template = {
	'@context': 'http://schema.org/',
	'@type': 'Product',
	name: '',
	mpn: '',
	sku: '',
	description: '',
	image: [],
	brand: {
		'@type': 'Brand',
		name: 'Diskubre Travel',
	},
	aggregateRating: {
		'@type': 'AggregateRating',
		ratingValue: 5,
		reviewCount: 0,
		bestRating: 5,
		worstRating: 4,
	},
	offers: {
		'@type': 'Offer',
		url: '',
		priceCurrency: 'USD',
		price: 0,
		priceValidUntil: '',
		itemCondition: 'https://schema.org/NewCondition',
		availability: 'http://schema.org/InStock',
		shippingDetails: {
			'@type': 'OfferShippingDetails',
			shippingRate: {
				'@type': 'MonetaryAmount',
				value: '0',
				currency: 'USD',
			},
		},
		hasMerchantReturnPolicy: {
			'@type': 'MerchantReturnPolicy',
			returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
			merchantReturnDays: 30,
			returnMethod: 'https://schema.org/ReturnInStore',
			returnFees: 'https://schema.org/FreeReturn',
		},
	},
};

export const generateJSONLd = (sku: string, rate: number, currency: string, reviewCount: number, name: string, description: string, url: string, images: Array<string>, reviews: Array<any> = []) => {
	const ld = { ...template };
	ld.mpn = sku;
	ld.sku = sku;
	ld.description = description;
	ld.image = images.map((i) => `https://www.diskubrepalawan.com${i}`);
	ld.name = name;
	ld.aggregateRating.reviewCount = reviewCount;
	ld.offers.price = rate;
	ld.offers.url = url;
	ld.offers.priceCurrency = currency;
	ld.offers.shippingDetails.shippingRate.currency = currency;
	ld.offers.priceValidUntil = newDay(31);
	business.review = generateReview(reviews);
	return JSON.stringify([business, ld]);
};

const convertDate = (dateString: string) => {
	// Define an object to map month names to numbers
	const months = {
		Jan: '01',
		Feb: '02',
		Mar: '03',
		Apr: '04',
		May: '05',
		Jun: '06',
		Jul: '07',
		Aug: '08',
		Sep: '09',
		Oct: '10',
		Nov: '11',
		Dec: '12',
	};

	// Split the date string into its components
	const [month, day, year] = dateString.split(' ');

	// Format the day to ensure it is two digits
	const formattedDay = day.length === 1 ? `0${day}` : day;

	// Get the month number from the months object
	const monthNumber = months[month];

	// Return the formatted date
	return `${year}-${monthNumber}-${formattedDay}`;
};
const generateReview = (reviews) => {
	const reviewData = reviews
		.filter((r) => r.reviewRating === 5)
		.map((r) => {
			return {
				'@type': 'Review',
				author: {
					'@type': 'Person',
					name: r.author,
				},
				datePublished: convertDate(r.revDate),
				reviewBody: r.reviewContent,
				reviewRating: {
					'@type': 'Rating',
					ratingValue: r.reviewRating,
				},
			};
		});
	return getRandomSubset(5, reviewData);
};

export const businessOnly = (reviews = []) => {
	business.review = generateReview(reviews);
	return JSON.stringify(business);
};

export const loadCSSIfNotExists = (urls) => {
	if (typeof window === 'undefined') {
		// Server-side, do nothing
		return;
	}

	for (let i = 0; i < urls.length; i++) {
		const url = urls[i];

		// Check if the CSS file is already loaded
		if (!document.querySelector(`link[href="${url}"]`)) {
			const link = document.createElement('link');

			// Defer loading by using 'preload' first
			link.rel = 'preload';
			link.as = 'style';
			link.href = url;

			// Once loaded, switch rel to 'stylesheet' to apply the style
			link.onload = () => {
				link.rel = 'stylesheet';
			};

			// Append the link element to the head
			document.head.appendChild(link);
		}
	}
};
